import { BufferGeometryNode, extend } from "@react-three/fiber"
import { geometry } from "maath"
extend(geometry)

declare module "@react-three/fiber" {
	interface ThreeElements {
		roundedPlaneGeometry: BufferGeometryNode<
			geometry.RoundedPlaneGeometry,
			typeof geometry.RoundedPlaneGeometry
		>
	}
}

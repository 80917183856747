import { twMerge } from "tailwind-merge"

interface HoloTextProps extends React.ComponentPropsWithoutRef<"div"> {
	children: React.ReactNode
	id?: string
	className?: string
}
export default function HoloText({ children, className, id, ...props }: HoloTextProps) {
	return (
		<div className="relative z-0">
			<span
				id={id}
				className={twMerge("holo-light dark:holo5 relative font-rubik text-4xl font-bold", className)}
				{...props}>
				{children}
			</span>
			<span
				className={twMerge(
					"absolute left-0 top-0 z-[-1] translate-x-[2px] translate-y-[2px] bg-black/60 bg-clip-text font-rubik text-4xl font-bold text-transparent blur-[4px]",
					className,
				)}>
				{children}
			</span>
		</div>
	)
}

import { HOLOGRAM_MAX_RGBD_DEPTHINESS } from "@/prisma/models"
import { RefObject, useEffect } from "react"
import { PlaneGeometry, Texture } from "three"

interface Options {
	plane: RefObject<PlaneGeometry>
	displacement: Texture
}

const useDisplaceGeometry = ({ plane, displacement }: Options) =>
	useEffect(() => {
		const geometry = plane.current
		if (!geometry) {
			return
		}
		const image: HTMLImageElement = displacement.image
		const canvas = document.createElement("canvas")
		const ctx = canvas.getContext("2d")

		if (!ctx) {
			throw new Error("Missing Canvas Context")
		}

		canvas.width = image.width
		canvas.height = image.height
		ctx.drawImage(image, 0, 0)
		const widthSegments = geometry.parameters.widthSegments + 1
		const heightSegments = geometry.parameters.heightSegments + 1
		const widthStep = image.width / widthSegments
		const heightStep = image.height / widthSegments

		for (let h = 0; h < heightSegments; h++) {
			for (let w = 0; w < widthSegments; w++) {
				const x = Math.round(w * widthStep)
				const y = Math.round(h * heightStep)
				const imageData = ctx.getImageData(x, y, 1, 1).data
				const displacement = (imageData[0] / 255) * HOLOGRAM_MAX_RGBD_DEPTHINESS
				const idx = h * widthSegments + w
				geometry.attributes.position.setZ(idx, displacement)
			}
		}
		geometry.attributes.position.needsUpdate = true
		geometry.computeVertexNormals()
		canvas.remove()

		console.log("displaced geometry")

		if (!geometry.attributes.uv) {
			throw new Error("Missing UVs")
		}
	}, [plane, displacement])

export default useDisplaceGeometry

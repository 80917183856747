import { animated } from "@react-spring/three"
import { GradientTexture, useMask } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useRef } from "react"
import { SpringValue } from "react-spring"
import { MeshBasicMaterial, Vector2 } from "three"

interface BorderGradientProps {
	aspectRatio: number
	opacity?: SpringValue<number>
}

export default function LoadingBorder({ aspectRatio, opacity }: BorderGradientProps) {
	const material = useRef<MeshBasicMaterial>(null)
	const stencil = useMask(1, true)

	useFrame(({ clock }) => {
		if (!material.current || !material.current.map || !material.current.alphaMap) return

		const rotation = -clock.getElapsedTime()
		material.current.alphaMap.rotation = rotation
		material.current.map.rotation = rotation
	})

	return (
		<mesh scale={1.05}>
			<roundedPlaneGeometry args={[1.05, 1.05 / aspectRatio, 0.05]} />
			<animated.meshBasicMaterial ref={material} transparent opacity={opacity ?? 0}>
				<GradientTexture
					stops={[0, 0.25, 0.5, 0.75, 1]} // As many stops as you want
					colors={["black", "#f9dcdd", "#a266f0", "#30dcff", "black"]} // Colors need to match the number of stops
					size={1024} // Size is optional, default = 1024
					center={new Vector2(0.5, 0.5)}
				/>
				<GradientTexture
					// Attach a black and white map with the same number of stops as the alpha map to the parent material
					attach={"alphaMap"}
					stops={[0, 0.25, 0.5, 0.75, 1]}
					colors={["black", "white", "white", "white", "black"]}
					size={1024}
					center={new Vector2(0.5, 0.5)}
				/>
			</animated.meshBasicMaterial>
		</mesh>
	)
}

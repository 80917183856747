import { useMask } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useRef } from "react"
import { Mesh } from "three"

interface Props {
	aspectRatio: number
}

export default function AspectContainer({ aspectRatio }: Props) {
	const stencil = useMask(1, true)

	return (
		<group renderOrder={0} visible={true}>
			<mesh scale={1.005}>
				<roundedPlaneGeometry args={[1, 1 / aspectRatio, 0.05]} />
				<meshBasicMaterial color="white" {...stencil} />
			</mesh>
			<mesh position={[0, 0, -0.01]}>
				<roundedPlaneGeometry args={[1, 1 / aspectRatio, 0.05]} />
				<meshBasicMaterial color="black" transparent opacity={0.5} />
			</mesh>
		</group>
	)
}

import { getCDNUrl } from "@/lib/cdn"
import { EmbedHologram } from "@/lib/hologramProps"
import {
	findSourceImage,
	HOLOGRAM_DEFAULT_ASPECT_RATIO,
	HOLOGRAM_DEFAULT_RGBD_DEPTHINESS,
	HOLOGRAM_DEFAULT_RGBD_FOCUS,
	HOLOGRAM_DEFAULT_RGBD_ZOOM,
	HOLOGRAM_MAX_RGBD_DEPTHINESS,
} from "@/prisma/models"
import { animated, useSpring } from "@react-spring/three"
import { useMask, useTexture } from "@react-three/drei"
import { ThreeEvent, useFrame } from "@react-three/fiber"
import { useEffect, useMemo, useRef, useState } from "react"
import { LinearSRGBColorSpace, Mesh, NearestFilter, PlaneGeometry, ShaderMaterial, Texture } from "three"
import { mapLinear } from "three/src/math/MathUtils"
import useDisplaceGeometry from "./hooks/useDisplaceGeometry"
import vert from "./shaders/basic_vert.glsl"
import frag from "./shaders/rgbd_frag.glsl"
import "./three.types"

interface DepthRendererProps {
	hologram: EmbedHologram
	onFocusChange?: (focus: number) => void
	onLoaded?: () => void
	editMode?: boolean
}

const prepareTexture = (texture: Texture) => {
	texture.anisotropy = 0
	texture.colorSpace = LinearSRGBColorSpace
	texture.minFilter = NearestFilter
	texture.magFilter = NearestFilter
}

export default function DepthRenderer(props: DepthRendererProps) {
	const { hologram, onLoaded, onFocusChange, editMode } = props
	let { aspectRatio, imageAssets, rgbdFocus, rgbdDepthiness, crop_pos_x, crop_pos_y, rgbdZoom } = hologram

	aspectRatio ||= HOLOGRAM_DEFAULT_ASPECT_RATIO
	rgbdFocus ||= HOLOGRAM_DEFAULT_RGBD_FOCUS
	rgbdDepthiness ||= HOLOGRAM_DEFAULT_RGBD_DEPTHINESS
	crop_pos_x ??= 0
	crop_pos_y ??= 0
	rgbdZoom ??= HOLOGRAM_DEFAULT_RGBD_ZOOM

	const meshRef = useRef<Mesh>(null)
	const geoRef = useRef<PlaneGeometry>(null)
	const stencil = useMask(1)

	const depthAsset = imageAssets.find((i) => i.kind === "DEPTH")
	const sourceAsset = findSourceImage(hologram)
	const blurUrl = getCDNUrl(sourceAsset!.url, { blur: 24 })

	const diffuseMap = useTexture(sourceAsset!.url, prepareTexture)
	const depthMap = useTexture(depthAsset!.url, prepareTexture)
	const blurMap = useTexture(blurUrl, prepareTexture)

	const depthCanvas = useRef<HTMLCanvasElement | null>(null)

	const [{ focusVisible }] = useSpring(() => ({ focusVisible: 0 }))
	const scaleZ = mapLinear(
		rgbdDepthiness,
		// TODO: Confirm true range values
		0.001, // Shouldn't this be HOLOGRAM_MIN_RGBD_DEPTHINESS?
		HOLOGRAM_MAX_RGBD_DEPTHINESS,
		0.003,
		0.9,
	)

	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		if (!loaded) {
			setLoaded(true)
			return
		}
		focusVisible.start({ from: 1, to: 0, delay: 0, config: { duration: 1000 } })
	}, [rgbdFocus])

	const uniforms = useMemo(
		() => ({
			diffuseMap: { value: diffuseMap },
			blurMap: { value: blurMap },
			depthMap: { value: depthMap },
			u_focus: { value: rgbdFocus },
			u_depthiness: { value: rgbdDepthiness },
			u_isFocusVisible: { value: 0 },
		}),
		[],
	)

	useFrame(() => {
		const material = meshRef.current?.material as ShaderMaterial
		material.uniforms.u_isFocusVisible.value = focusVisible.get()
	})

	useEffect(() => {
		const material = meshRef.current?.material as ShaderMaterial
		material.uniforms.u_focus.value = rgbdFocus / rgbdDepthiness
		material.uniforms.u_depthiness.value = rgbdDepthiness
	}, [rgbdFocus, rgbdDepthiness])

	useEffect(() => {
		onLoaded?.()

		const canvas = document.createElement("canvas")
		const depthImage = depthMap.image as HTMLImageElement
		const width = depthImage.width
		const height = depthImage.height
		canvas.width = width
		canvas.height = height
		const ctx = canvas.getContext("2d")
		if (!ctx) return
		ctx.drawImage(depthImage, 0, 0, width, height)
		depthCanvas.current = canvas
		return () => {
			depthCanvas.current?.remove()
		}
	}, [])

	useDisplaceGeometry({
		plane: geoRef,
		displacement: depthMap,
	})

	const [mouseDownTimestamp, setMouseDownTimestamp] = useState<number | null>(null)

	const onPointerDown = (e: ThreeEvent<PointerEvent>) => {
		if (!editMode || e.buttons !== 1) return
		setMouseDownTimestamp(Date.now())
	}

	const onPointerUp = (e: ThreeEvent<PointerEvent>) => {
		if (!editMode) return
		const uv = e.uv
		if (!mouseDownTimestamp) return
		setMouseDownTimestamp(null)
		// console.log(Date.now() - mouseDownTimestamp!)
		if (Date.now() - mouseDownTimestamp! > 200) return
		if (!uv || !depthCanvas.current) return
		const ctx = depthCanvas.current.getContext("2d")
		// Get the image data at coordinates (x, y)
		// Transform UV coordinates to pixel coordinates
		const x = Math.floor(uv.x * depthCanvas.current.width)
		const y = Math.floor((1 - uv.y) * depthCanvas.current.height)

		const imageData = ctx?.getImageData(x, y, 1, 1).data

		if (!imageData) return
		// Extract the RGBA values
		const value = imageData[0]
		const normalized = -mapLinear(value, 0, 255, -1, 1)
		onFocusChange?.(normalized * rgbdDepthiness)

		focusVisible.start({ from: 1, to: 0, delay: 500, config: { duration: 1000 } })
	}

	return (
		<animated.mesh ref={meshRef} scale-z={scaleZ} onPointerDown={onPointerDown} onPointerUp={onPointerUp}>
			<planeGeometry ref={geoRef} args={[1, 1 / aspectRatio, 320, 320]} />
			<shaderMaterial
				uniforms={uniforms}
				vertexShader={vert}
				fragmentShader={frag}
				transparent
				{...stencil}
			/>
		</animated.mesh>
	)
}

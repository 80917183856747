import { useBreakpoint } from "@/hooks/useBreakpoints"
import { EmbedHologram } from "@/lib/hologramProps"
import {
	HOLOGRAM_DEFAULT_ASPECT_RATIO,
	HOLOGRAM_DEFAULT_RGBD_ZOOM,
	HOLOGRAM_MIN_QUILT_ZOOM,
} from "@/prisma/models"
import { animated, config, easings, useSpring } from "@react-spring/three"
import { Center, Mask } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber"
import { Handler, useGesture } from "@use-gesture/react"
import { RefObject, Suspense, useEffect, useState } from "react"
import { MathUtils } from "three"
import { mapLinear } from "three/src/math/MathUtils"
import AspectContainer from "./AspectContainer"
import DepthRenderer from "./DepthRenderer"
import FallbackBlock from "./FallbackBlock"
import LoadingBorder from "./LoadingBorder"
import QuiltRenderer from "./QuiltRenderer"
import "./three.types"
import { MAX_RGBD_RADIANS, MAX_QUILT_RADIANS } from "./utils"

interface BlockContainerProps {
	hologram: EmbedHologram
	interactionContainer?: RefObject<HTMLElement>
	onZoomChange?: (zoom: number) => void
	onOffsetChange?: (x: number, y: number) => void
	onFocusChange?: (focus: number) => void
	editMode?: boolean
	overrideAspect?: number
}

const { lerp, smootherstep, clamp } = MathUtils

export default function BlockContainer(props: BlockContainerProps) {
	const {
		hologram,
		onZoomChange,
		onOffsetChange,
		onFocusChange,
		editMode,
		overrideAspect,
		interactionContainer,
	} = props
	let { aspectRatio, quiltTileCount, rgbdZoom, crop_pos_x, crop_pos_y, type } = hologram

	aspectRatio = aspectRatio ?? HOLOGRAM_DEFAULT_ASPECT_RATIO
	quiltTileCount = quiltTileCount ?? 1
	rgbdZoom = rgbdZoom ?? HOLOGRAM_DEFAULT_RGBD_ZOOM
	crop_pos_x = crop_pos_x ?? 0
	crop_pos_y = crop_pos_y ?? 0
	const springConfig = { ...config.gentle, friction: type === "RGBD" ? 20 : 14 }
	const maxAngle = type === "QUILT" ? MAX_QUILT_RADIANS : MAX_RGBD_RADIANS

	const isDesktop = useBreakpoint("md")
	const [hover, setHover] = useState(false)
	const [locked, setLock] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const [introDone, setIntroDone] = useState(false)
	const [loadedTimestamp, setLoadedTimestamp] = useState<number | null>(null)
	const [containerScale, setContainerScale] = useState(1)

	const { viewport, gl } = useThree(({ viewport, gl }) => ({ viewport, gl }))

	const { factor } = viewport
	const canvas = gl.domElement
	const containerAspect = overrideAspect ?? aspectRatio

	const minZoom = type === "RGBD" ? HOLOGRAM_DEFAULT_RGBD_ZOOM : HOLOGRAM_MIN_QUILT_ZOOM

	const remappedScale = mapLinear(rgbdZoom, minZoom, 2, 1, 4)
	const heightReadjust = containerAspect > aspectRatio ? aspectRatio / containerAspect : 1
	const aspectScale = remappedScale * heightReadjust

	const { opacity, rotationX, position, scale, rotationY } = useSpring({
		opacity: loaded ? 0 : 1,
		position: [-crop_pos_x * aspectScale, (-crop_pos_y * aspectScale) / aspectRatio, 0],
		rotationY: 0,
		rotationX: 0,
		scale: aspectScale,
		config: (key) => {
			if (key === "scale") {
				return { duration: 50, easing: easings.linear }
			}
			if (key === "opacity") {
				return { duration: 300, easings: easings.easeInOutQuint }
			}
			if (key === "position" || key === "scale") {
				return config.gentle
			}
			if (key === "y") {
				return {}
			}
			return {}
		},
	})

	const onPointerLeave = () => {
		rotationY.start(0, { delay: 500, config: springConfig })
		rotationX.start(0, { delay: 500, config: springConfig })
	}

	const onMove = (element: HTMLElement) => {
		const move: Handler<"move", PointerEvent> = ({ event, pressed }) => {
			if (loaded) setIntroDone(true)
			if (pressed || locked) return

			const { clientX, clientY } = event
			const rect = element.getBoundingClientRect()
			const normalizedX = smootherstep(clientX, rect.left, rect.right)

			const angle = lerp(-maxAngle, maxAngle, normalizedX)

			rotationY.start(angle, { config: springConfig })

			if (type === "RGBD") {
				const normalizedY = smootherstep(clientY, rect.top, rect.bottom)
				const verticalAngle = lerp(-maxAngle, maxAngle, normalizedY)
				rotationX.start(verticalAngle, { config: springConfig })
			}
		}
		return move
	}

	useGesture({ onMove: onMove(canvas), onPointerLeave }, { target: canvas, enabled: loaded && !locked })
	useGesture(
		{ onMove: onMove(interactionContainer?.current!), onPointerLeave },
		{ target: interactionContainer, enabled: loaded && !locked },
	)

	useGesture(
		{
			onAuxClick: () => {
				if (!locked) {
					rotationX.start(0, { config: springConfig })
					rotationY.start(0, { config: springConfig })
				}
				setLock(!locked)
			},
			onDrag: ({ delta: [x, y], event, dragging }) => {
				if (isDesktop) {
					if (!dragging || !editMode) return

					const newX = clamp(crop_pos_x - x, (-1 / containerAspect) * 2, 1)
					const newY = clamp(crop_pos_y - y, -1, 1)

					onOffsetChange?.(newX, newY)
				} else {
					// Drag controls rotate the container on mobile
					const { clientX, clientY } = event as PointerEvent
					console.log("dragging")
					const rect = canvas.getBoundingClientRect()
					const normalizedX = smootherstep(clientX, rect.left, rect.right)
					const normalizedY = smootherstep(clientY, rect.top, rect.bottom)

					const angle = lerp(-maxAngle, maxAngle, normalizedX)
					rotationY.start(angle, { config: config.gentle })

					if (type === "RGBD") {
						const verticalAngle = lerp(-maxAngle, maxAngle, normalizedY)
						rotationX.start(verticalAngle, { config: config.gentle })
					}

					// Handles release event
					if (!dragging) {
						rotationY.start(0, { delay: 250, config: springConfig })
						rotationX.start(0, { delay: 250, config: springConfig })
					}
				}
			},
			onWheel: ({ delta: [_, y], wheeling, dragging }) => {
				if (!wheeling || dragging || !editMode) return
				const deltaY = y
				const scale = mapLinear(rgbdZoom ?? 1, 0.5, 2, 1, 2)
				const newZoom = clamp(scale + deltaY / 350, 1, 2)

				const remappedZoom = mapLinear(newZoom, 1, 2, 0.5, 2)
				onZoomChange?.(remappedZoom)
			},
		},
		{
			drag: {
				transform: ([x, y]) => [
					x / factor / containerScale / aspectScale,
					-y / factor / containerScale / aspectScale,
				],
			},
			target: canvas,
			enabled: loaded && introDone,
		},
	)

	// Wiggle into animation
	useFrame(({ clock }) => {
		const timestamp = clock.getElapsedTime()
		if (!loadedTimestamp && loaded) {
			setLoadedTimestamp(timestamp)
			return
		}
		if (introDone || !loadedTimestamp) return

		const now = clock.getElapsedTime()
		const elapsed = now - loadedTimestamp

		if (elapsed > 0.5) {
			rotationY.start(0, {
				config: { tension: 200, friction: 6, mass: 0.5 },
				onRest() {
					setIntroDone(true)
				},
			})
			return
		}

		const sin = Math.sin(elapsed * 10)
		const angle = mapLinear(sin, -1, 1, -maxAngle / 2, maxAngle / 2)
		rotationY.start(angle, { config: { tension: 100, friction: 6 } })
	})

	const onLoaded = () => {
		setLoaded(true)
	}

	useEffect(() => {
		if (!editMode) return
		if (hover) {
			if (type === "QUILT") {
				canvas.classList.add("cursor-grab", "active:cursor-grabbing")
			} else {
				canvas.classList.add("cursor-crosshair", "active:cursor-grabbing")
			}
		} else canvas.classList.remove("cursor-grab", "active:cursor-grabbing", "cursor-crosshair")
	}, [hover])

	return (
		<animated.group
			key="group"
			onPointerEnter={() => setHover(true)}
			onPointerLeave={() => setHover(false)}
			scale={containerScale}
			rotation-x={rotationX}
			rotation-y={rotationY}>
			<Center
				onCentered={({ width, height }) => {
					const scalar = viewport.aspect < containerAspect ? viewport.width / width : viewport.height / height
					setContainerScale(scalar)
				}}>
				<FallbackBlock hologram={hologram} opacity={opacity} overrideAspect={containerAspect} />
				<LoadingBorder aspectRatio={containerAspect} opacity={opacity} />

				{/* This geometry pads the viewport to prevent clipping the renderer at extreme angles  */}
				{type === "QUILT" && (
					<group>
						<group rotation-y={-maxAngle}>
							<mesh position-x={0.55} position-y={0.55 / aspectRatio} visible={false}>
								<sphereGeometry args={[0.1]} />
							</mesh>
							<mesh position-x={0.55} position-y={-0.55 / aspectRatio} visible={false}>
								<sphereGeometry args={[0.1]} />
							</mesh>
						</group>
						<group rotation-y={maxAngle}>
							<mesh position-x={-0.55} position-y={0.55 / aspectRatio} visible={false}>
								<sphereGeometry args={[0.1]} />
							</mesh>
							<mesh position-x={-0.55} position-y={-0.55 / aspectRatio} visible={false}>
								<sphereGeometry args={[0.1]} />
							</mesh>
						</group>
					</group>
				)}
				{type === "RGBD" && (
					<group>
						<group rotation-x={maxAngle} rotation-y={-maxAngle}>
							<mesh position-x={0.7} position-y={0.7 / aspectRatio} visible={false}>
								<sphereGeometry args={[0.1]} />
							</mesh>
						</group>
						<group rotation-x={maxAngle} rotation-y={maxAngle}>
							<mesh position-x={-0.7} position-y={0.7 / aspectRatio} visible={false}>
								<sphereGeometry args={[0.1]} />
							</mesh>
						</group>
						<group rotation-x={-maxAngle} rotation-y={-maxAngle}>
							<mesh position-x={0.7} position-y={-0.7 / aspectRatio} visible={false}>
								<sphereGeometry args={[0.1]} />
							</mesh>
						</group>
						<group rotation-x={-maxAngle} rotation-y={maxAngle}>
							<mesh position-x={-0.7} position-y={-0.7 / aspectRatio} visible={false}>
								<sphereGeometry args={[0.1]} />
							</mesh>
						</group>
					</group>
				)}
			</Center>

			<Mask id={1}>
				<roundedPlaneGeometry args={[1, 1 / containerAspect, 0.05]} />
			</Mask>
			<AspectContainer aspectRatio={containerAspect} />

			<Suspense>
				<animated.group
					key={"content"}
					// @ts-ignore
					position={position}
					// @ts-ignore
					scale={scale}>
					{type === "QUILT" && <QuiltRenderer hologram={hologram} onLoaded={onLoaded} />}
					{type === "RGBD" && (
						<DepthRenderer
							hologram={hologram}
							onLoaded={onLoaded}
							onFocusChange={onFocusChange}
							editMode={editMode}
						/>
					)}
				</animated.group>
			</Suspense>
		</animated.group>
	)
}

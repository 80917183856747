import { EmbedHologram } from "@/lib/hologramProps"
import { HOLOGRAM_DEFAULT_ASPECT_RATIO } from "@/prisma/models"
import { animated, SpringValue } from "@react-spring/three"
import { useMask, useTexture } from "@react-three/drei"
import { getCDNRect } from "../HologramThumbnail"
import "./three.types"

interface Props {
	hologram: EmbedHologram
	opacity?: SpringValue<number>
	overrideAspect?: number
}

export default function FallbackBlock({ hologram, opacity, overrideAspect }: Props) {
	const aspectRatio = overrideAspect ?? (hologram?.aspectRatio || HOLOGRAM_DEFAULT_ASPECT_RATIO)
	const url = getCDNRect({ hologram, blur: 24, width: 750, height: 750 / aspectRatio })
	const texture = useTexture(url)
	const stencil = useMask(1)

	return (
		<mesh renderOrder={2} scale={1.2}>
			<roundedPlaneGeometry args={[1, 1 / aspectRatio, 0.05]} />
			<animated.meshBasicMaterial
				toneMapped={false}
				depthTest={false}
				map={texture}
				transparent={true}
				opacity={opacity}
				{...stencil}
			/>
		</mesh>
	)
}

import { EmbedHologram } from "@/lib/hologramProps"
import { HOLOGRAM_DEFAULT_ASPECT_RATIO } from "@/prisma/models"
import { Canvas } from "@react-three/fiber"
import { RefObject } from "react"
import { LinearSRGBColorSpace } from "three"
import BlockContainer from "./BlockContainer"
import { twMerge } from "tailwind-merge"

interface BlockCanvasProps {
	onZoomChange?: (zoom: number) => void
	onOffsetChange?: (x: number, y: number) => void
	onFocusChange?: (focus: number) => void
	hologram: EmbedHologram
	interactionContainer?: RefObject<HTMLElement>
	className?: string
	editMode?: boolean
	overrideAspect?: number
}
export default function BlockCanvas(props: BlockCanvasProps) {
	const {
		onZoomChange,
		onOffsetChange,
		onFocusChange,
		hologram,
		interactionContainer,
		className,
		editMode,
		overrideAspect,
	} = props
	const aspectRatio = hologram.aspectRatio ?? HOLOGRAM_DEFAULT_ASPECT_RATIO
	const { type } = hologram

	return (
		<Canvas
			camera={{ fov: 45 / 2 }}
			style={{
				aspectRatio: overrideAspect || aspectRatio,
				touchAction: "pan-y",
				// R3F Canvas writes this style object after the className.
				// Revert the properties here so tailwind passes through
				width: "revert-layer",
				height: "revert-layer",
			}}
			className={twMerge("h-auto w-full", className)}
			gl={{ stencil: true, outputColorSpace: LinearSRGBColorSpace, localClippingEnabled: true }}>
			<style jsx global>
				{`
					canvas {
						touch-action: pan-y;
					}
				`}
			</style>
			<BlockContainer
				onOffsetChange={onOffsetChange}
				onZoomChange={onZoomChange}
				onFocusChange={onFocusChange}
				hologram={hologram}
				interactionContainer={interactionContainer}
				editMode={editMode}
				overrideAspect={overrideAspect}
			/>
		</Canvas>
	)
}

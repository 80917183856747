import { AppState, useStore } from "@/hooks/useStore"
import { getCDNUrl } from "@/lib/cdn"
import { EmbedHologram } from "@/lib/hologramProps"
import { normalize } from "@/lib/utils"
import { findSourceImage, HOLOGRAM_DEFAULT_RGBD_ZOOM } from "@/prisma/models"
import { useMask, useTexture } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useEffect, useMemo, useRef } from "react"
import { LinearMipMapNearestFilter, Mesh, NearestFilter, NearestMipMapLinearFilter, Vector3 } from "three"
import { clamp } from "three/src/math/MathUtils"
import vert from "./shaders/basic_vert.glsl"
import frag from "./shaders/quilt_frag.glsl"
import { MAX_QUILT_RADIANS } from "./utils"

interface BlocksRendererProps {
	hologram: EmbedHologram
	onLoaded?: () => void
	editMode?: boolean
}

export default function QuiltRenderer(props: BlocksRendererProps) {
	const { hologram, onLoaded } = props
	let { quiltCols, quiltRows, aspectRatio, quiltTileCount, rgbdZoom, crop_pos_x, crop_pos_y } = hologram
	quiltCols = quiltCols ?? 1
	quiltRows = quiltRows ?? 1
	quiltTileCount = quiltTileCount ?? quiltCols * quiltRows
	aspectRatio = aspectRatio ?? 0.75

	rgbdZoom = rgbdZoom ?? HOLOGRAM_DEFAULT_RGBD_ZOOM
	crop_pos_x = crop_pos_x ?? 0
	crop_pos_y = crop_pos_y ?? 0

	const sourceImage = findSourceImage(hologram)

	const { width, height } = sourceImage!

	const cellWidth = width / quiltCols
	const cellHeight = height / quiltRows

	const url = getCDNUrl(sourceImage!.url)
	const quilt = useTexture(url, (texture) => {
		texture.minFilter = NearestFilter
	})
	const ref = useRef<Mesh>(null)
	const stencil = useMask(1)
	const setAngle = useStore((state: AppState) => state.setHologramAngle)

	useEffect(() => {
		onLoaded?.()
	}, [])

	const uniforms = useMemo(
		() => ({
			u_frame_index: { value: quiltTileCount / 2 - 1 },
			u_rows: { value: quiltRows },
			u_columns: { value: quiltCols },
			u_quilt: { value: quilt },
			u_opacity: { value: 1 },
		}),
		[],
	)

	useFrame(() => {
		if (!ref.current) return

		const forward = ref.current.getWorldDirection(new Vector3(0, 0, 0))
		const radians = forward.angleTo(new Vector3(0, 0, 1)) * Math.sign(forward.x)
		const normalized = normalize(radians, MAX_QUILT_RADIANS, -MAX_QUILT_RADIANS)

		let angleIndex = quiltTileCount - Math.floor(normalized * quiltTileCount) - 1
		angleIndex = clamp(angleIndex, 0, quiltTileCount - 1)
		setAngle(normalized)
		// @ts-ignore
		ref.current.material.uniforms.u_frame_index.value = angleIndex
	})

	return (
		<mesh ref={ref} renderOrder={0}>
			<roundedPlaneGeometry args={[1, 1 / aspectRatio, 0.05]} />
			<shaderMaterial
				transparent
				vertexShader={vert}
				fragmentShader={frag}
				uniforms={uniforms}
				{...stencil}
			/>
		</mesh>
	)
}
